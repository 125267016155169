import React, { Fragment } from "react";
import DataTable from "react-data-table-component";
import TopBarProgress from "react-topbar-progress-indicator";
import { ToastContainer, toast } from "react-toastify";
import * as XLSX from "xlsx";
import Sidebar from "../components/sidebar.component";
import Footer from "../components/footer.component";
import Navbar from "../components/navbar.component";
import "./dashboard.css";
import { useNavigate } from "react-router-dom";
import { DOMAIN, DOMAIN_SERVER, scrapeData } from "../../utils/states";
import axios from "axios";
import { Modal, ModalManager } from "react-dynamic-modal/lib/Modal";
import { Effect } from "react-dynamic-modal";

const columns = [
  {
    name: "Title",
    selector: "title",
    sortable: true,
    grow: 2,
  },
  {
    name: "Phone",
    selector: "phone",
    sortable: true,
    allowOverflow: true,
    grow: 1,
  },

  {
    name: "Street",
    selector: "street",
    sortable: true,
    cell: (row) => <span title={row.street}>{row.street}</span>,
    grow: 1,
  },
  {
    name: "State",
    selector: "state",
    sortable: true,
  },
  {
    name: "Zip",
    selector: "zip",
    sortable: true,
  },
  {
    name: "Rating",
    selector: "rating",
    sortable: true,
  },
  {
    name: "Reviews",
    selector: "reviews",
    sortable: true,
  },
  // {
  //   name: 'Ad',
  //   selector: 'ad',
  //   sortable: true,
  // },
  {
    name: "Website",
    selector: "website",
    sortable: true,
    cell: (row) => (
      <div>
        {row.website !== "" && typeof row.website !== "undefined" ? (
          <a href={row.website} target="_blank" rel="noopener noreferrer">
            <i className="fas fa-link" />
          </a>
        ) : (
          "-"
        )}
      </div>
    ),
  },
  {
    name: "Google Business Link",
    selector: "gmb",
    sortable: true,
    cell: (row) => (
      <div>
        {row.gmb !== "" && typeof row.gmb !== "undefined" ? (
          <a href={row.gmb} target="_blank" rel="noopener noreferrer">
            <i className="fas fa-link" />
          </a>
        ) : (
          "-"
        )}
      </div>
    ),
  },
];

const paginationOptions = {
  rowsPerPageText: "Data per page",
  rangeSeparatorText: "of",
  selectAllRowsItem: true,
  selectAllRowsItemText: "All",
};

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
      queries: [],
      allQueries: [],
      count: {},
      currentQuery: "",
      filteredData: [],
      email: localStorage.getItem("email"),
      activeTab:
        window.location.href?.indexOf("listing") !== -1 ? "listing" : "folders",
      folders: [],
      checkedNiche: [],
      currentActiveFolder: null,
    };
    this.downloadXLSX = this.downloadXLSX.bind(this);
    this.removeDuplicates = this.removeDuplicates.bind(this);
    this.removeListData = this.removeListData.bind(this);
  }

  removeDuplicates(originalArray, prop) {
    var newArray = [];
    var lookupObject = {};

    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }

  async componentDidMount() {
    await this.syncFolders();

    const queriesData = await axios.post(`${DOMAIN_SERVER}/api/get-niche`, {
      user: this.state.email,
    });

    const queries = queriesData.data;

    this.setState({ queries: queries, allQueries: queries, loading: false });
  }

  downloadXLSX() {
    return (
      <button
        className="btn btn-sm btn-info"
        disabled={this.state.data && this.state.data.length ? false : true}
        onClick={() => {
          this.pushToExcel();
        }}
      >
        Download Excel
      </button>
    );
  }

  back = () => {
    return (
      <button
        className="btn btn-sm btn-info"
        onClick={() => {
          this.setState({ currentQuery: "" });
        }}
      >
        Back
      </button>
    );
  };

  removeListData() {
    return (
      <button
        className="btn btn-sm btn-danger"
        onClick={() => {
          this.clearData();
        }}
        disabled={this.state.data && this.state.data.length ? false : true}
      >
        Remove Data
      </button>
    );
  }

  pushToExcel() {
    var temp = [];

    this.state.filteredData.forEach((e) => {
      var _temp = {
        title: e.title,
        address: e.address,
        phone: e.phone,
        website: e.website,
      };
      temp.push(_temp);
    });

    var ws = XLSX.utils.json_to_sheet(temp);

    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Presidents");

    XLSX.writeFile(wb, `Grab-Map-Leads-Data.xlsx`);
  }

  clearData() {
    this.setState({ loading: true }, () => {
      // chrome.storage.local.remove(["scrapeData"], () => {
      //   this.setState({ loading: false });
      //   toast("Data has been removed !", { containerId: "B" });
      //   window.location.reload();
      // });
    });
  }

  navigate = (nicheId) => {
    localStorage.setItem("currentQuery", nicheId);
    this.props.navigate("/crm");
  };

  setStateAsync = (component, newState) => {
    return new Promise((resolve) => {
      component.setState(newState, () => {
        resolve();
      });
    });
  };

  remove = async (item) => {
    await this.setStateAsync(this, { loading: true });

    await axios.post(`${DOMAIN_SERVER}/api/delete-niche`, {
      user: this.state.email,
      niche: item,
    });

    this.componentDidMount();
  };

  logout = (props) => {
    localStorage.removeItem("profile");
    const navigate = useNavigate();
    navigate("/login");
  };

  searchData = (e) => {
    if (e === "") {
      this.setState({ queries: this.state.allQueries, activeTab: "listing" });
      return;
    }

    const foundQueries = this.state.allQueries.filter((q) => {
      const searchTerm = e.toLowerCase();

      const matchesNiche = q?.niche?.toLowerCase().includes(searchTerm);
      const matchesQuery = q?.query?.toLowerCase().includes(searchTerm);
      const matchesCities = q?.cities?.some((city) =>
        city.toLowerCase().includes(searchTerm)
      );
      const matchesState = q?.state?.toLowerCase().includes(searchTerm);

      return matchesNiche || matchesQuery || matchesCities || matchesState;
    });

    this.setState({ queries: foundQueries, activeTab: "listing" });
  };

  loadingHandler = async (value) => {
    await this.setStateAsync(this, {
      loading: value,
    });
  };

  syncFolders = async () => {
    var userId = localStorage.getItem("user_id");
    var folders = [];

    if (
      typeof userId === "undefined" ||
      userId === "" ||
      userId === "null" ||
      userId === null
    ) {
      localStorage.removeItem("email");
      window.location.href = "/";
    } else {
      var foldersRes = await axios.get(
        `https://app.leadgorilla.io/api/get-folders/${userId}`
      );

      folders = foldersRes.data || [];
    }

    await this.setStateAsync(this, {
      folders,
    });

    return folders;
  };

  syncNiches = async () => {
    const queriesData = await axios.post(`${DOMAIN_SERVER}/api/get-niche`, {
      user: this.state.email,
    });

    const queries = queriesData.data;

    await this.setStateAsync(this, {
      queries: queries,
      allQueries: queries,
    });
  };

  deleteFolder = async (folder) => {
    try {
      await this.loadingHandler(true);
      var userId = localStorage.getItem("user_id");

      if (
        typeof userId === "undefined" ||
        userId === "" ||
        userId === "null" ||
        userId === null
      ) {
        localStorage.removeItem("email");
        window.location.href = "/";
      } else {
        await axios.post(`${DOMAIN_SERVER}/api/delete-folder`, {
          id: folder?.id,
          user_id: userId,
        });

        await this.syncFolders();

        await this.loadingHandler(false);

        return true;
      }
    } catch (error) {
      await this.loadingHandler(false);
    }
  };

  handleCheckboxChange = async (e, item) => {
    const { checkedNiche } = this.state;
    const checked = e.target.checked;

    if (checked) {
      await this.setStateAsync(this, {
        checkedNiche: [...checkedNiche, item.id],
      });
    } else {
      await this.setStateAsync(this, {
        checkedNiche: checkedNiche.filter((niche) => niche !== item.id),
      });
    }
  };

  updateBulkNicheFolder = async (folder) => {
    await this.setStateAsync(this, { loading: true });
    try {
      await axios.post(`${DOMAIN_SERVER}/api/update-bulk-niche-folder`, {
        checkedNiche: this.state.checkedNiche,
        bulkSelectedFolder: folder,
        user: this.state.email,
      });
    } catch (error) {
      console.log(error);

      alert("Unable to perform bulk action. Please try again!");
    } finally {
      await this.setStateAsync(this, { loading: false, checkedNiche: [] });
      this.componentDidMount();
    }
  };

  viewFolder = async (folder) => {
    await this.setStateAsync(this, { queries: this.state.allQueries });
    console.log(this.state.queries);
    console.log(folder);

    const niches = this.state.queries?.filter((q) => {
      if (q.folder == folder.id) {
        return q;
      }
    });
    await this.setStateAsync(this, {
      queries: niches,
      activeTab: "listing",
      currentActiveFolder: folder,
    });
  };

  render() {
    TopBarProgress.config({
      barColors: {
        0: "#CB3837",
        0.5: "#C836C3",
        "1.0": "#29ABE2",
      },
      shadowBlur: 5,
    });

    const { queries, folders } = this.state;

    return (
      <div id="wrapper">
        {/* <Sidebar /> */}
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Navbar current="dashboard" />

            <div className="container-fluid">
              <Fragment>
                {this.state.loading && <TopBarProgress />}

                <div className="container">
                  <div className="search mt-1">
                    <i className="fa fa-search"></i>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search location..."
                      onChange={(e) => {
                        this.searchData(e.target.value);
                      }}
                    />
                    <button
                      className="btn btn-primary"
                      style={{
                        background: "#5a5c69",
                        borderColor: "#5a5c69",
                      }}
                    >
                      Search
                    </button>
                  </div>
                  <br />
                  <div style={{ marginTop: 50 }}>
                    <button
                      className={`btn btn-primary ${
                        this.state.activeTab === "folders" ? "activeBtn" : ""
                      }`}
                      style={{
                        background: "#5a5c69",
                        borderColor: "#5a5c69",
                      }}
                      onClick={() => {
                        this.setState({
                          activeTab: "folders",
                          checkedNiche: [],
                        });
                      }}
                    >
                      Folders
                    </button>
                    <button
                      className={`btn btn-primary ${
                        this.state.activeTab === "listing" ? "activeBtn" : ""
                      }`}
                      style={{
                        background: "#5a5c69",
                        borderColor: "#5a5c69",
                        marginLeft: 10,
                      }}
                      onClick={() => {
                        this.setState({ activeTab: "listing" });
                      }}
                    >
                      Listing
                    </button>

                    {this.state.activeTab === "folders" && (
                      <button
                        className={`btn btn-success`}
                        style={{
                          float: "right",
                        }}
                        onClick={() => {
                          ModalManager.open(
                            <CreateFolder
                              onRequestClose={async () => {
                                await this.syncFolders();
                                await this.syncNiches();
                              }}
                            />
                          );
                        }}
                      >
                        Create Folder
                      </button>
                    )}

                    {this.state.checkedNiche?.length > 0 && (
                      <select
                        value={this.state.bulkSelectedFolder}
                        onChange={(e) =>
                          this.updateBulkNicheFolder(e.target.value)
                        }
                        style={{
                          color: "gray",
                          float: "right",
                        }}
                      >
                        <option value="">Select Folder</option>
                        {this.state.folders?.map((folder) => (
                          <option key={folder?.id} value={folder?.id}>
                            {folder?.title}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>

                  <div style={{ textAlign: "center" }}>
                    {this.state.activeTab === "folders" && (
                      <div className="mt-3">
                        {folders?.length > 0 ? (
                          folders.map((item, index) => (
                            <div
                              key={index}
                              className="d-style btn btn-brc-tp border-2 bgc-white btn-outline-blue btn-h-outline-blue btn-a-outline-blue my-2 py-3 shadow-sm abscdefg"
                              style={{
                                background: "white",
                                width: "85%",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  width: "85%",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  className="col-12 col-md-3"
                                  style={{
                                    textAlign: "left",
                                    paddingLeft: 40,
                                    top: -1,
                                  }}
                                >
                                  <h5 className="pt-3 text-170 text-600 text-primary-d1 letter-spacing">
                                    {item?.title}
                                  </h5>

                                  <div className="text-secondary-d1 text-120 mt-3">
                                    Number of Lists :{" "}
                                    {Number(
                                      item?.totalNiches
                                    )?.toLocaleString()}
                                  </div>
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    width: "35%",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div className="col-12 col-md-3 text-center">
                                    <button
                                      className="f-n-hover btn btn-info btn-raised px-4 py-25 text-600"
                                      style={{
                                        marginTop: 5,
                                        width: "160px",
                                      }}
                                      onClick={() => {
                                        this.viewFolder(item);
                                      }}
                                    >
                                      View
                                    </button>
                                  </div>

                                  <div className="col-12 col-md-3 text-center">
                                    <button
                                      className="f-n-hover btn btn-danger btn-raised px-4 py-25 text-600"
                                      style={{
                                        marginTop: 5,
                                        width: "160px",
                                      }}
                                      onClick={() => {
                                        this.deleteFolder(item);
                                      }}
                                    >
                                      Delete Folder
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div>
                            <div
                              className="row"
                              style={{
                                justifyContent: "center",
                                marginTop: "4%",
                              }}
                            >
                              <h5 className="mt-10">
                                {this.state.loading
                                  ? "Loading, please wait!"
                                  : "No search found!"}
                              </h5>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  {this.state.activeTab === "listing" && (
                    <div className="mt-3">
                      {queries?.length > 0 ? (
                        queries.map((item, index) => (
                          <div
                            key={index}
                            className="d-style btn btn-brc-tp border-2 bgc-white btn-outline-blue btn-h-outline-blue btn-a-outline-blue w-100 my-2 py-3 shadow-sm abscdefg"
                            style={{ background: "white" }}
                          >
                            <div className="row align-items-center">
                              <div
                                className="col-12 col-md-5"
                                style={{
                                  textAlign: "left",
                                  paddingLeft: 40,
                                  top: -9,
                                }}
                              >
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id={`checkbox-${index}`}
                                  style={{
                                    marginTop: -1,
                                    marginLeft: -33,
                                    transform: "scale(0.8)",
                                  }}
                                  checked={this.state?.checkedNiche?.includes(
                                    item.id
                                  )}
                                  onChange={(e) =>
                                    this.handleCheckboxChange(e, item)
                                  }
                                />
                                <h5 className="pt-3 text-170 text-600 text-primary-d1 letter-spacing">
                                  Niche: {item?.query}
                                </h5>
                                <h5 className="text-160 text-600 text-primary-d1">
                                  State: {item?.state}
                                </h5>
                                <div className="text-secondary-d1 text-120">
                                  Cities:
                                  {+item?.selectAllCities === 1
                                    ? " All cities"
                                    : " Selected"}
                                  <CityList item={item} />
                                </div>

                                <div className="text-secondary-d1 text-120 mt-3">
                                  <span className="text-180">
                                    {Number(item?.totalGmb)?.toLocaleString()}
                                  </span>{" "}
                                  / leads
                                </div>
                                <div
                                  className="text-secondary-d1 text-120"
                                  style={{ fontSize: 13 }}
                                >
                                  <span
                                    className="text-180"
                                    style={{ fontSize: 13 }}
                                  >
                                    Domains Synced: (
                                    {Number(
                                      item?.syncedEmails
                                    )?.toLocaleString()}
                                  </span>{" "}
                                  /{" "}
                                  {Number(item?.totalEmails)?.toLocaleString()})
                                </div>

                                <div
                                  className="text-secondary-d1 text-120"
                                  style={{ fontSize: 13 }}
                                >
                                  <span
                                    className="text-180"
                                    style={{ fontSize: 13 }}
                                  >
                                    Contactable Leads: (
                                    {Number(
                                      item?.gmbsWithEmails
                                    )?.toLocaleString()}
                                    )
                                  </span>{" "}
                                </div>
                                <div
                                  className="text-secondary-d1 text-120"
                                  style={{ fontSize: 13 }}
                                >
                                  <span
                                    className="text-180"
                                    style={{
                                      fontSize: 13,
                                    }}
                                  >
                                    Folder:{" "}
                                    <span
                                      style={{
                                        textDecoration: "underline",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {item?.folder_title || "n/a"}
                                    </span>
                                  </span>{" "}
                                </div>
                              </div>
                              <ul className="list-unstyled mb-0 col-12 col-md-3 text-dark-l1 text-90 text-left my-4 my-md-0">
                                <li>
                                  <i
                                    className="fa fa-check text-success-m2 text-110 mr-2 mt-1"
                                    style={{ color: "green" }}
                                  />
                                  <span>
                                    <span className="text-110">Website</span>
                                  </span>
                                </li>
                                <li className="mt-25">
                                  <i
                                    className="fa fa-check text-success-m2 text-110 mr-2 mt-1"
                                    style={{ color: "green" }}
                                  />
                                  <span className="text-110">Phone #</span>
                                </li>
                                <li className="mt-25">
                                  <i
                                    className="fa fa-check text-success-m2 text-110 mr-1 mt-1"
                                    style={{ color: "green" }}
                                  />
                                  <span className="text-110">Email</span>
                                </li>
                              </ul>
                              <div className="col-12 col-md-3 text-center">
                                <button
                                  className="f-n-hover btn btn-info btn-raised px-4 py-25 w-75 text-600"
                                  style={{ background: "#49c496" }}
                                  onClick={() => {
                                    this.navigate(item?.id);
                                  }}
                                >
                                  View Leads
                                </button>
                              </div>
                              <div className="col-12 col-md-1 text-center">
                                <button
                                  onClick={() => {
                                    this.remove(item?.niche);
                                  }}
                                  className="btn btn-sm btn-danger _removeQuery"
                                  style={{ cursor: "pointer" }}
                                >
                                  X
                                </button>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div>
                          <div
                            className="row"
                            style={{
                              justifyContent: "center",
                              marginTop: "4%",
                            }}
                          >
                            <h5 className="mt-10">
                              {this.state.loading
                                ? "Loading, please wait!"
                                : "No search found!"}
                            </h5>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </Fragment>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

class CityList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMore: false,
    };
  }

  toggleShowMore = () => {
    this.setState((prevState) => ({
      showMore: !prevState.showMore,
    }));
  };

  render() {
    const { item } = this.props;
    const { showMore } = this.state;

    const maxVisibleCities = 8;
    const cities = item?.cities || [];
    const visibleCities = showMore ? cities : cities.slice(0, maxVisibleCities);

    return (
      <div>
        <span className="text-180">{visibleCities.join(", ")}</span>

        {cities.length >= maxVisibleCities && (
          <span
            style={{
              color: "black",
              fontSize: 14,
              marginLeft: 39,
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              ModalManager.open(
                <CityFullList
                  onRequestClose={() => true}
                  state={item.state}
                  cities={item.cities}
                  selectAllCities={item.selectAllCities}
                />
              );
            }}
          >
            See More...
          </span>
        )}
      </div>
    );
  }
}

function DashboardPage() {
  const navigate = useNavigate();

  return <Dashboard navigate={navigate} />;
}

export default DashboardPage;

class CityFullList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      state: this.props.state,
      cities: this.props.cities,
      selectAllCities: this.props.selectAllCities,
    };
  }

  async componentDidMount() {
    if (+this.state.selectAllCities === 1) {
      axios
        .get(`${DOMAIN}/api/fetch-cities/${this.state.state}`)
        .then((response) => {
          response.data = response.data.sort((a, b) => {
            if (a.city < b.city) return -1;
            if (a.city > b.city) return 1;
            return 0;
          });

          this.setState({ cities: response.data });
        })
        .catch((error) => {
          console.error("Error fetching cities:", error);
        });
    }
  }

  render() {
    const { onRequestClose } = this.props;
    return (
      <Modal
        onRequestClose={onRequestClose}
        effect={Effect.ScaleUp}
        style={{
          content: {
            height: 435,
            top: -171,
          },
        }}
      >
        <div className="modal-header">
          <h4 className="modal-title">Searched Cities</h4>

          <button
            type="button"
            className="btn btn-danger"
            onClick={ModalManager.close}
          >
            Close
          </button>
        </div>
        <div className="modal-body">
          <div className="row badge-container">
            {this.state.cities?.map((city, index) => (
              <span
                key={index}
                className="badge badge-secondary"
                style={{ marginRight: "8px", color: "white" }}
              >
                {city?.city || city}
              </span>
            ))}
          </div>
        </div>
      </Modal>
    );
  }
}

class CreateFolder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      color: "",
      user_id: localStorage.getItem("user_id"),
    };
  }

  createFolder = async () => {
    if (this.state.title?.length === 0) {
      return false;
    }
    try {
      const { title, color, user_id } = this.state;

      await axios.post(`${DOMAIN_SERVER}/api/store-folder`, {
        title,
        color,
        user_id,
      });

      this.props.onRequestClose();
      ModalManager.close();
    } catch (error) {
      console.log(error);

      alert("Unable to create folder, please try again!");
    }
  };

  render() {
    const { onRequestClose } = this.props;
    return (
      <Modal
        onRequestClose={onRequestClose}
        effect={Effect.ScaleUp}
        style={{
          content: {
            width: 600,
          },
        }}
      >
        <div className="modal-header">
          <h4 className="modal-title">Create a new folder</h4>
        </div>
        <div className="modal-body" style={{ height: 100 }}>
          <div className="col col-lg-12 col-md-12" style={{ display: "flex" }}>
            <div className="col col-md-12">
              <input
                className="form-control"
                onChange={(e) => {
                  this.setState({
                    title: e.target.value,
                  });
                }}
                value={this.state.title}
                placeholder="Folder title"
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-success"
            onClick={this.createFolder}
            style={{ marginRight: 35 }}
          >
            Create Folder
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={ModalManager.close}
          >
            Close
          </button>
        </div>
      </Modal>
    );
  }
}
