import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/img/logo.png";

const user =
  localStorage.getItem("profile") !== "undefined"
    ? JSON.parse(localStorage.getItem("profile"))?.user
    : null;

const Logout = () => {
  localStorage.removeItem("profile");
  // const navigate = useNavigate();
  // navigate('/login');
  window.location.href = "/";
};

const Navbar = (props) => {
  const navigate = useNavigate();
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <a
        className="navbar-brand"
        style={{ cursor: "pointer", color: "white" }}
        onClick={() => {
          navigate("/dashboard");
        }}
      >
        <img
          src={logo}
          style={{
            width: 186,
            marginTop: "-6%",
            marginBottom: "-7%",
            marginLeft: -16,
            height: 62,
          }}
        />
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarColor02"
        aria-controls="navbarColor02"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>
      <div className="collapse navbar-collapse" id="navbarColor02">
        <ul className="navbar-nav mr-auto" style={{ marginLeft: 30 }}>
          <li
            className={`nav-item ${
              props.current === "dashboard" || props.current === "crm"
                ? "active"
                : ""
            }`}
          >
            <a
              className="nav-link"
              onClick={() => {
                navigate("/dashboard");
              }}
            >
              Lists <span className="sr-only">(current)</span>
            </a>
          </li>
          <li
            className={`nav-item ${props.current === "search" ? "active" : ""}`}
          >
            <a
              className="nav-link"
              onClick={() => {
                navigate("/search");
              }}
            >
              Search
            </a>
          </li>
          <li
            className={`nav-item ${
              props.current === "integrations" ? "active" : ""
            }`}
          >
            <a
              className="nav-link"
              onClick={() => {
                navigate("/integrations");
              }}
            >
              Integrations
            </a>
          </li>
          {/* <li
            className={`nav-item ${
              props.current === "folders" ? "active" : ""
            }`}
          >
            <a
              className="nav-link"
              onClick={() => {
                navigate("/folders");
              }}
            >
              Folders
            </a>
          </li> */}

          <li
            className={`nav-item ${
              props.current === "support" ? "active" : ""
            }`}
          >
            <a
              className="nav-link"
              onClick={() => {
                navigate("/support");
              }}
            >
              Support
            </a>
          </li>
        </ul>

        <ul className="navbar-nav ml-auto">
          <li className="nav-item dropdown no-arrow d-sm-none">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="searchDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fas fa-search fa-fw" />
            </a>
          </li>

          <div className="topbar-divider d-none d-sm-block" />
          <li className="nav-item dropdown no-arrow">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="userDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                {user && user.full_name}
              </span>
              <img
                width={28}
                className="img-profile rounded-circle"
                src={
                  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQJDEnnVNYD3wOk0PIywPT-2m4UETU7lhOSFBO2HywWPvrH7-g45Eh4Rq9F9WxZTYONKU&usqp=CAU"
                }
              />
            </a>
            <div
              className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="userDropdown"
            >
              <a
                className="dropdown-item"
                href="https://app.leadgorilla.io/account"
                target="_blank"
              >
                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400" />
                Profile
              </a>
              <hr />
              <a
                className="dropdown-item"
                href="https://billing.stripe.com/p/login/4gw8yrffv61r2WI000"
                target="_blank"
              >
                <i className="fas fa-dollar-sign fa-sm fa-fw mr-2 text-gray-400" />
                Billing
              </a>
              <hr />
              <a
                className="dropdown-item"
                href="#"
                data-toggle="modal"
                data-target="#logoutModal"
                onClick={() => {
                  Logout(props);
                }}
              >
                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />
                Logout
              </a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
